import { useEffect, useState, useRef, useContext, PropsWithChildren, useCallback } from 'react';
import 'styles/RoomPlanner.css';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  getRoomConfig,
  getRoomInfo,
  getShowTooltip,
  getTemplatesData,
  getUniversityId,
  saveRoomConfig,
  saveShowTooltip,
} from 'context/localStorage';
import {
  Snackbar,
  CircularProgress,
  CopyButton,
  CustomButton,
  CustomIconButtonSecondary,
  RadioField,
  InputFieldWithLabel,
  UniversityCatalog,
  PhotoViewerDialog,
  CancelIcon,
  EditConfigDialog,
  RightMeasurementButton,
  RoomViewerOnScreenText,
  // StyleIcon,
} from 'components';
import Drawer from '@material-ui/core/Drawer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Routes from 'routes';
import useStyles from 'styles/templateStyles';

import {
  layoutConfigToRoomItems,
  roomSceneToLayoutItems,
  isBunk,
  isBedFrameType,
  MATTRESS_ANCHOR,
  BUNK_ANCHOR,
  isMattressType,
  isPillowType,
  Messages,
  GET,
  POST,
  ROOM_SAVED_SUCCESS,
  ROOM_RESET_SUCCESS,
  ROOM_CONFIGURATIONS,
  getOrigin,
  STATUS_OPTIONS,
} from 'utils';
import {
  LayoutItem,
  UniversityCatalogProps,
  UniversityCatalogItemProps,
  Toast,
  ConfigsType,
  GuideView,
  ConfigStyle,
} from 'types';
import useAxios, { ServerRoutes } from 'api';
import { AxiosError } from 'axios';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Colors from 'theme';
import UpDownKeys from 'assets/upDownKeys.png';
import {
  ItemBedFeatureSlot,
  ItemMattressFeatureSlot,
  ItemModel,
  ItemModelSubType,
  ItemModelType,
  RoomModel,
  RoomScene,
  Vector3,
} from '@roomie-engineering/room-viewer';
import { ItemMaterialProps } from '@roomie-engineering/room-viewer';
import { AuthContext } from 'context/authContext';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { RoomViewer } from 'components/RoomViewer';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Button as ButtonMUI,
  IconButton,
  Modal,
  Tab,
  Tabs,
  makeStyles,
} from '@material-ui/core';
import { Delete, Info } from '@material-ui/icons';
import { sortBy } from 'lodash-es';
import AsyncAutocomplete from 'components/autocomplete/AsyncAutocomplete';
import AppliedStyles from 'components/lists/AppliedStyles';
//import { AutocompleteStyleItem } from 'types';

interface ButtonProps {
  background?: string;
  border?: string;
  title: string;
  onClick: () => void;
  solid?: boolean;
  width?: string;
  borderRadius?: string;
  enable?: boolean;
}

const initialConfigs = {
  bedConfig: '',
  standardBed: '',
  loftBed: '',
  bunkBed: '',
  studentCount: '',
};

const initialConfig = {
  modelUrl: '',
  panoUrl: '',
  roomDescription: '',
  configName: '',
  friendlyName: '',
  typeName: '',
};

const SHOW_BED_TOGGLE_CONFIGURATIONS = false;

const STYLE_INITIAL = {
  styleId: '',
  configId: '',
  configStyleItems: [],
};

const AdminPortal = (): JSX.Element => {
  const [tabValue, setTabValue] = useState(0);

  const classes = useStyles();
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const [, dummyState] = useState(false);
  const refresh = useCallback(() => dummyState((prev) => !prev), []);

  const [roomSceneState, setRoomSceneState] = useState<RoomScene | null>(null);
  const [catalogs, setCatalogs] = useState<UniversityCatalogProps[]>([]);
  const [showButton, setShowButton] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [status, setStatus] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showEditConfigModal, setShowEditConfigModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [toast, setToast] = useState<Toast>();
  const [showTooltip, setShowTooltip] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isMeasuring, setIsMeasuring] = useState(false);
  const [clearMeasurementHovered, setClearMeasurementHovered] = useState(false);
  const [isBedItem, setIsBed] = useState(false);
  const [token, setToken] = useState('');
  const rConfig = getRoomConfig();
  const roomInfo = getRoomInfo();
  const [roomConfigs, setRoomConfigs] = useState<ConfigsType>(initialConfigs);
  const [onScreenTextvalue, setOnScreenTextValue] = useState<string>('');
  const [configInfo, setConfigInfo] = useState(initialConfig);
  const [previousTemplate, setPreviousTemplate] = useState<GuideView | null>(null);
  const [nextTemplate, setNextTemplate] = useState<GuideView | null>(null);
  const [showMeasureButton, setShowMeasureButton] = useState(false);
  const [isEnableCatalogList, setIsEnableCatalogList] = useState<boolean>(false);
  const [style, setStyle] = useState<any>(STYLE_INITIAL);
  const [activeMode, setActiveMode] = useState<'CreateStyleAssociation' | 'TemplateView' | 'UpdateStyleAssociation'>(
    'TemplateView',
  );
  const [appliedStyles, setAppliedStyles] = useState<ConfigStyle | null>(null);
  const [styleItemIds, setStyleItemIds] = useState<any>([]);

  const [isAddBedItemOpen, setIsAddBedItemOpen] = useState(false);
  const [fixedStyleItems, setFixedStyleItems] = useState<ItemModel[]>([]);
  const [selectedStyleItem, setSelectedStyleItem] = useState<ItemModel | null>(null);

  const [isRemoveBedItemOpen, setIsRemoveBedItemOpen] = useState(false);
  const [itemsOnBed, setItemsOnBed] = useState<ItemModel[]>([]);
  const [selectedBedItemToRemove, setSelectedBedItemToRemove] = useState<ItemModel | null>(null);

  const [selectedBedItem, setSelectedBedItem] = useState<ItemModel | null>(null);

  const roomSceneStateRef = useRef(roomSceneState);
  const listRef = useRef<HTMLDivElement>(null);

  const setRoomSceneStateRef = (roomScene: RoomScene | null) => {
    if (roomScene) {
      setRoomSceneState(roomScene);
      roomSceneStateRef.current = roomScene;
    }
  };

  const params = new URLSearchParams(window.location.search);
  const isLocked = params.get('isLocked');

  const {
    callApi: loadUniversityCatalogs,
    loading: catalogsLoading,
    error: catalogsError,
  } = useAxios(ServerRoutes.adminLoadUniversityCatalogs, POST, false);

  const {
    callApi: loadLayout,
    response: loadLayoutRes,
    loading: loadLayoutLoading,
    error: loadLayoutError,
  } = useAxios(ServerRoutes.adminLoadLayout, GET, false);

  const {
    callApi: saveLayout,
    loading: saveLayoutLoading,
    error: saveLayoutError,
  } = useAxios(ServerRoutes.adminSaveLayout, POST, false);

  const { callApi: createAssociationApi } = useAxios(ServerRoutes.createAssociation, POST, false);
  // const { callApi: createAssociationApi } = useAxios(ServerRoutes.createAssociation, GET, false);

  const {
    // response: rooms,
    callApi: loadRooms,
    loading: roomsLoading,
    error: roomsError,
  } = useAxios(ServerRoutes.adminRoomsByConfig, POST, false);

  const {
    callApi: editConfig,
    loading: editConfigLoading,
    error: editConfigError,
  } = useAxios(ServerRoutes.adminEditConfig, POST, false);

  const {
    callApi: updateRoomStatus,
    loading: updateStatusLoading,
    error: updateStatusError,
  } = useAxios(ServerRoutes.adminUpdateStatus, POST, false);

  const { callApi: getAssociatedTepmplateStyles } = useAxios(ServerRoutes.createAssociation, GET, false);

  const getAssociatedStyles = async () => {
    await getAssociatedTepmplateStyles({ configId: rConfig?.roomieRoomConfigId }, (styles) => {
      if (styles?.data) {
        const filteredStyles: any = styles?.data?.filter((style: any) => style.styleId && style.styleName);
        setAppliedStyles(filteredStyles);
      }
    });
  };

  useEffect(() => {
    if (!rConfig.roomieRoomConfigId) history.push(Routes.DEFAULT);
    const fetchCatalogs = async () => {
      const id = getUniversityId();
      await loadUniversityCatalogs(
        {
          university_id: id ? id : roomInfo.orgId,
        },
        (catalogsResp) => {
          if (catalogsResp.data?.catalogs) {
            setCatalogs(catalogsResp.data.catalogs);
          }
        },
      );
    };
    fetchCatalogs();

    const loadItems = async () => {
      await loadAdminLayout(rConfig);
      setToken(rConfig.token);
      window.addEventListener('keydown', keyPressEventListener);
    };
    if (rConfig.roomieRoomConfigId) {
      loadItems();
    }
    getAssociatedStyles();
    return function cleanUp() {
      window.removeEventListener('keydown', keyPressEventListener);
    };
  }, []);

  useEffect(() => {
    const loadHeights = async () => {
      if (roomSceneState) await applyHeight(roomSceneState, loadLayoutRes?.data.templateItems);
    };
    if (isLoaded) setTimeout(() => loadHeights(), 1000);
  }, [isLoaded]);

  useEffect(() => {
    const showError = (error: AxiosError | undefined) => {
      if (error) {
        setShowToast(true);
        setToast({
          severity: 'error',
          message: error.message,
          autoHideDuration: 3000,
        });
      }
    };
    showError(loadLayoutError);
    showError(updateStatusError);
    showError(saveLayoutError);
    showError(catalogsError);
    showError(roomsError);
    showError(editConfigError);
  }, [loadLayoutError, saveLayoutError, catalogsError, roomsError, editConfigError, updateStatusError]);

  const hideTooltip = () => {
    saveShowTooltip('false');
    setShowTooltip(false);
  };

  const keyPressEventListener = (evt: KeyboardEvent) => {
    if (roomSceneStateRef.current) {
      const selectedItem = roomSceneStateRef?.current?.selectedRoom?.selectedItem;
      if (selectedItem) {
        const bedFeature = selectedItem.getBedFeature();
        if (bedFeature) {
          if (evt.key == 'ArrowUp') {
            bedFeature.adjustFrameHeight(true);
            hideTooltip();
          } else if (evt.key == 'ArrowDown') {
            bedFeature.adjustFrameHeight(false);
            hideTooltip();
          }
        }
      }
    }
  };

  const loadAdminLayout = async (currentConfig: GuideView, changingTemplate = false) => {
    console.log('Starting loadAdminLayout', { currentConfig, changingTemplate });
    const templatesData = getTemplatesData();
    console.log('Templates data:', templatesData);

    try {
      console.log('Calling loadLayout with:', { id: currentConfig.roomieRoomConfigId, token: '' });
      await loadLayout({ id: currentConfig.roomieRoomConfigId, token: '' }, async (loadLayoutResp) => {
        console.log('loadLayout response:', loadLayoutResp);

        if (loadLayoutResp && loadLayoutResp.data.code === 0) {
          console.log('Valid loadLayout response received');
          const data = loadLayoutResp?.data;
          console.log('Layout data:', data);

          setStatus(data?.status || 'Active');
          console.log('Status set to:', data?.status || 'Active');

          if (roomInfo.admin) {
            console.log('Admin user, loading rooms');
            await loadRooms({ roomie_room_config_id: data?.roomieRoomConfigId });
          }

          const config = {
            modelUrl: data.roomie_model_url || '',
            panoUrl: data?.panoUrl || '',
            roomDescription: data?.roomDescription || '',
            configName: data?.roomieConfigName || '',
            friendlyName: data?.friendlyName || '',
            typeName: data?.type || '',
          };
          console.log('Setting config info:', config);
          setConfigInfo(config);

          let next: GuideView | null = null,
            previous: GuideView | null = null;
          templatesData.forEach((item, index) => {
            if (templatesData[index].roomieRoomConfigId === data?.roomieRoomConfigId) {
              if (index > 0) previous = templatesData[index - 1];
              if (index < templatesData.length - 1) next = templatesData[index + 1];
            }
          });
          console.log('Setting next and previous templates:', { next, previous });
          setNextTemplate(next);
          setPreviousTemplate(previous);

          console.log('Checking conditions for room creation:', {
            roomSceneState: !!roomSceneState,
            changingTemplate,
          });
          if (roomSceneState && changingTemplate) {
            console.log('Conditions met, creating room');
            saveRoomConfig(currentConfig);

            console.log('Adding room to roomSceneState');
            const room = roomSceneState.addRoom({
              title: data?.building_name || '',
              assetUrl: data?.roomie_model_url,
              isLocked: isLocked === 'true' && !roomInfo.admin,
              setIsLoaded: setIsLoaded,
            });

            console.log('Room added:', room);
            console.log('Template items:', data?.templateItems);

            // Create a root node for the layout tree
            const layoutTree: LayoutItem = {
              roomie_user_room_item_id: 'root',
              children: data?.templateItems || [],
            };

            console.log('Layout tree created:', layoutTree);
            console.log('Starting recursive addition of items');
            await recursiveAddToRoom(layoutTree, room);

            console.log('Items added to room');
            console.log('Setting selected room');
            roomSceneState.setSelectedRoom(room);
          } else {
            console.log('Conditions not met for room creation');
          }
        } else {
          console.log('Invalid or error response from loadLayout');
        }
      });
    } catch (error) {
      console.error('Error in loadAdminLayout:', error);
    }

    console.log('loadAdminLayout completed');
  };

  const copy = () => {
    const origin = getOrigin();
    const url = `${origin}${Routes.TEMPLATE}?id=${token}`;
    const textField = document.createElement('textarea');
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    if (navigator) navigator.clipboard.writeText(url);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 10000);
  };

  const setValueInRoomConfigs = (key: string, value: string | number) => {
    setRoomConfigs((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const notBed = () => {
    setIsBed(false);
    setShowTooltip(false);
  };

  const scrollToProduct = (productId: string) => {
    const listItem = document.querySelector(`#item-${productId}`);
    if (listItem) {
      listItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const refreshMaterial = (): void => {
    console.log('Refreshing material');
  };

  const onItemSelected = (item?: ItemModel) => {
    if (item) {
      if (item?.props?.catalogId) {
        scrollToProduct(item?.props?.catalogId);
      }

      let isBedItemSelected = false;
      let bedItem: ItemModel | null = null;

      if (item.type === ItemModelType.Bed) {
        isBedItemSelected = true;
        bedItem = item;
      } else if (item.parent && item.parent.type === ItemModelType.Bed) {
        isBedItemSelected = true;
        bedItem = item.parent;
      }

      if (isBedItemSelected) {
        setIsBed(true);
        setSelectedBedItem(bedItem);
        if (getShowTooltip() === 'true') {
          setShowTooltip(true);
        }
      } else {
        notBed();
        setSelectedBedItem(null);
      }

      setShowButton(true);
    } else {
      setShowButton(false);
      notBed();
      setSelectedBedItem(null);
    }
  };

  const getItemsOnBed = (): ItemModel[] => {
    if (!selectedBedItem) return [];

    const bedFeature = selectedBedItem.getBedFeature();
    if (!bedFeature) return [];

    const items: ItemModel[] = [];

    // Get bed slot items
    const bedSlots = [
      ItemBedFeatureSlot.Riser,
      ItemBedFeatureSlot.Mattress,
      ItemBedFeatureSlot.MattressTopper,
      ItemBedFeatureSlot.Skirt,
    ];

    bedSlots.forEach((slot) => {
      const item = bedFeature.getSlotItem(slot);
      if (item) items.push(item);
    });

    // Get mattress slot items
    const mattress = bedFeature.getSlotItem(ItemBedFeatureSlot.Mattress);
    if (mattress) {
      const mattressFeature = mattress.getMattressFeature();
      if (mattressFeature) {
        const mattressSlots = [
          ItemMattressFeatureSlot.Sheets,
          ItemMattressFeatureSlot.Cover,
          ItemMattressFeatureSlot.Pillow,
          ItemMattressFeatureSlot.ShamPillow,
          ItemMattressFeatureSlot.DecorativePillow,
          ItemMattressFeatureSlot.AccentPillow,
          ItemMattressFeatureSlot.ThrowBlanket,
          ItemMattressFeatureSlot.Headboard,
        ];
        mattressSlots.forEach((slot) => {
          const slotCount = mattressFeature.getSlotCount(slot);
          for (let i = 0; i < slotCount; i++) {
            const slotItem = mattressFeature.getSlotItem(slot, i);
            if (slotItem) {
              items.push(slotItem);
            }
          }
        });
      }
    }

    return items;
  };

  const handleAddBedItemClick = () => {
    const styleItems = style?.configStyleItems || [];

    const itemsInStyle = styleItems
      .map((styleItem: any) => {
        return roomSceneState?.selectedRoom?.getItemById(styleItem.id);
      })
      .filter((item: ItemModel | undefined): item is ItemModel => item !== undefined);

    setFixedStyleItems(itemsInStyle);
    setIsAddBedItemOpen(true);
  };

  const handleRemoveBedItemClick = () => {
    const items = getItemsOnBed();
    setItemsOnBed(items);
    setIsRemoveBedItemOpen(true);
  };

  const addBedItem = (item: ItemModel) => {
    console.log('Attempting to add item to bed:', item);
    if (!selectedBedItem) {
      console.error('No bed item selected');
      return;
    }

    const proceed = () => {
      const type = item.type;
      const bedFeature = selectedBedItem.getBedFeature();
      if (!bedFeature) {
        console.error('Selected bed item does not have bed feature');
        return;
      }

      console.log('Adding item of type:', type);

      if (type === ItemModelType.Riser) {
        bedFeature.addSlotItem(ItemBedFeatureSlot.Riser, item);
        console.log('Added Riser to bed');
      } else if (type === ItemModelType.Mattress) {
        bedFeature.removeMattressAndMattressTopper();
        bedFeature.setSlotItem(ItemBedFeatureSlot.Mattress, item);
        console.log('Added Mattress to bed');
      } else if (type === ItemModelType.MattressTopper) {
        const mattress = bedFeature.getSlotItem(ItemBedFeatureSlot.Mattress);
        if (mattress) {
          mattress.getMattressFeature()?.clearAllSlotItems();
        } else {
          bedFeature.addDefaultItemToSlot(ItemBedFeatureSlot.Mattress);
        }
        bedFeature.replaceSlotItem(ItemBedFeatureSlot.MattressTopper, item);
        console.log('Added Mattress Topper to bed');
      } else if (type === ItemModelType.Skirt) {
        bedFeature.replaceSlotItem(ItemBedFeatureSlot.Skirt, item);
        console.log('Added Skirt to bed');
      } else if (type === ItemModelType.Sheets) {
        bedFeature.getMattressFeature()?.addSlotItem(ItemMattressFeatureSlot.Sheets, item);
        console.log('Added sheets to bed');
      } else if (type === ItemModelType.Cover) {
        bedFeature.getMattressFeature()?.replaceSlotItem(ItemMattressFeatureSlot.Cover, item);
        console.log('Added cover to bed');
      } else if (type === ItemModelType.Pillow) {
        const mattressFeature = bedFeature.getMattressFeature();
        if (mattressFeature) {
          const count = mattressFeature.getSlotCount(ItemMattressFeatureSlot.Pillow);
          if (count === mattressFeature.getSlotCountMax(ItemMattressFeatureSlot.Pillow)) {
            mattressFeature.clearSlotItems(ItemMattressFeatureSlot.Pillow);
          }
          mattressFeature.addSlotItem(ItemMattressFeatureSlot.Pillow, item);
          console.log('Added Pillow to bed');
        }
      } else if (type === ItemModelType.ShamPillow) {
        const mattressFeature = bedFeature.getMattressFeature();
        if (mattressFeature) {
          const count = mattressFeature.getSlotCount(ItemMattressFeatureSlot.ShamPillow);
          if (count === mattressFeature.getSlotCountMax(ItemMattressFeatureSlot.ShamPillow)) {
            mattressFeature.clearSlotItems(ItemMattressFeatureSlot.ShamPillow);
          }
          mattressFeature.addSlotItem(ItemMattressFeatureSlot.ShamPillow, item);
          console.log('Added Sham Pillow to bed');
        }
      } else if (type === ItemModelType.DecorativePillow) {
        const mattressFeature = bedFeature.getMattressFeature();
        if (mattressFeature) {
          const count = mattressFeature.getSlotCount(ItemMattressFeatureSlot.DecorativePillow);
          if (count === mattressFeature.getSlotCountMax(ItemMattressFeatureSlot.DecorativePillow)) {
            mattressFeature.clearSlotItems(ItemMattressFeatureSlot.DecorativePillow);
          }
          mattressFeature.addSlotItem(ItemMattressFeatureSlot.DecorativePillow, item);
          console.log('Added Decorative Pillow to bed');
        }
      } else if (type === ItemModelType.AccentPillow) {
        const mattressFeature = bedFeature.getMattressFeature();
        if (mattressFeature) {
          const count = mattressFeature.getSlotCount(ItemMattressFeatureSlot.AccentPillow);
          if (count === mattressFeature.getSlotCountMax(ItemMattressFeatureSlot.AccentPillow)) {
            mattressFeature.clearSlotItems(ItemMattressFeatureSlot.AccentPillow);
          }
          mattressFeature.addSlotItem(ItemMattressFeatureSlot.AccentPillow, item);
          console.log('Added Accent Pillow to bed');
        }
      } else if (type === ItemModelType.ThrowBlanket) {
        bedFeature.getMattressFeature()?.replaceSlotItem(ItemMattressFeatureSlot.ThrowBlanket, item);
        console.log('Added Throw Blanket to bed');
      } else if (type === ItemModelType.Headboard) {
        bedFeature.getMattressFeature()?.replaceSlotItem(ItemMattressFeatureSlot.Headboard, item);
        console.log('Added Headboard to bed');
      }
      refreshMaterial();
    };

    if (item.asset.isLoaded()) {
      proceed();
    } else {
      item.asset.getLoadedObservable().add(proceed);
    }
  };

  const removeBedItem = (item: ItemModel) => {
    if (!selectedBedItem) {
      console.error('No bed item selected');
      return;
    }

    const bedFeature = selectedBedItem.getBedFeature();
    if (!bedFeature) {
      console.error('Selected bed item does not have bed feature');
      return;
    }

    const type = item.type;

    // Remove the item from the bed features
    if (type === ItemModelType.Riser) {
      bedFeature.clearSlotItems(ItemBedFeatureSlot.Riser, true);
    } else if (type === ItemModelType.Mattress) {
      bedFeature.removeMattressAndMattressTopper();
    } else if (type === ItemModelType.MattressTopper) {
      bedFeature.clearSlotItem(ItemBedFeatureSlot.MattressTopper, 0, true);
    } else if (type === ItemModelType.Skirt) {
      bedFeature.clearSlotItem(ItemBedFeatureSlot.Skirt, 0, true);
    } else {
      // Handle mattress features
      const mattress = bedFeature.getSlotItem(ItemBedFeatureSlot.Mattress);
      if (mattress) {
        const mattressFeature = mattress.getMattressFeature();
        if (mattressFeature) {
          const slots = [
            ItemMattressFeatureSlot.Sheets,
            ItemMattressFeatureSlot.Cover,
            ItemMattressFeatureSlot.Pillow,
            ItemMattressFeatureSlot.ShamPillow,
            ItemMattressFeatureSlot.DecorativePillow,
            ItemMattressFeatureSlot.AccentPillow,
            ItemMattressFeatureSlot.ThrowBlanket,
            ItemMattressFeatureSlot.Headboard,
          ];
          for (const slot of slots) {
            const slotCount = mattressFeature.getSlotCount(slot);
            for (let i = 0; i < slotCount; i++) {
              const slotItem = mattressFeature.getSlotItem(slot, i);
              if (slotItem && slotItem.itemId === item.itemId) {
                mattressFeature.clearSlotItem(slot, i, true);
                break;
              }
            }
          }
        }
      }
    }

    item.parent = undefined;
    item.setParent(undefined);
    item.anchor = undefined;
    item.props.anchor = undefined;
    const position = new Vector3(0, 0, 0);
    item.setPosition(position);

    refreshMaterial();
  };

  const clearBedItems = () => {
    if (!selectedBedItem) {
      console.error('No bed item selected');
      return;
    }

    const bedFeature = selectedBedItem.getBedFeature();
    if (!bedFeature) {
      console.error('Selected bed item does not have bed feature');
      return;
    }

    const itemsToDetach = getItemsOnBed();

    const mattress = bedFeature.getSlotItem(ItemBedFeatureSlot.Mattress);
    if (mattress) {
      const mattressFeature = mattress.getMattressFeature();
      if (mattressFeature) {
        const slots = [
          ItemMattressFeatureSlot.Sheets,
          ItemMattressFeatureSlot.Cover,
          ItemMattressFeatureSlot.Pillow,
          ItemMattressFeatureSlot.ShamPillow,
          ItemMattressFeatureSlot.DecorativePillow,
          ItemMattressFeatureSlot.AccentPillow,
          ItemMattressFeatureSlot.ThrowBlanket,
          ItemMattressFeatureSlot.Headboard,
        ];
        for (const slot of slots) {
          mattressFeature.clearSlotItems(slot, true);
        }
      }
    }

    bedFeature.clearSlotItems(ItemBedFeatureSlot.Riser, true);
    bedFeature.clearSlotItem(ItemBedFeatureSlot.Skirt, 0, true);

    itemsToDetach.forEach((item) => {
      item.parent = undefined;
      item.setParent(undefined);
      item.props.parentItemId = undefined;
      item.anchor = undefined;
      item.props.anchor = undefined;

      const position = new Vector3(0, 0, 0);
      item.setPosition(position);
    });

    bedFeature.removeAllMattressesAndMattressToppers();
    bedFeature.addDefaultItemToSlot(ItemBedFeatureSlot.Mattress);

    refreshMaterial();
  };

  const clearMeasurementPressed = () => {
    setIsMeasuring(false);
    roomSceneState?.measureTool.deactivate();
  };

  const onMeasureTool = (active: boolean, count?: number): void => {
    if (active) {
      setIsMeasuring(true);
      setShowMeasureButton(true);
      count != undefined
        ? count == 0
          ? setOnScreenTextValue('Click another point to make a measurement')
          : setOnScreenTextValue('')
        : setOnScreenTextValue('Click anywhere to start a measurement');
    } else {
      setIsMeasuring(false);
      setShowMeasureButton(false);
      setOnScreenTextValue('');
    }
  };

  const applyHeight = async (scene: RoomScene, template: LayoutItem[]) => {
    if (scene) {
      const beds = scene.selectedRoom?.getAllItems().filter((item) => isBedFrameType(item.type || ''));
      beds?.forEach((item) => {
        const bedItem = template.filter(
          (resItem: LayoutItem) =>
            (resItem.parentItemId === item.itemId || resItem.itemId === item.itemId) &&
            isBedFrameType(resItem?.itemDetail?.type || ''),
        );
        if (bedItem) {
          renderBedWithHeight(bedItem[0].height ?? -1, item);
        }
      });
    }
  };

  const renderBedWithHeight = async (height: number, bed: ItemModel) => {
    if (height > -1) {
      const feature = bed.getBedFeature();
      feature?.setFrameHeight(height);
    }
    setIsLoaded(false);
  };

  const getBeds = () => {
    const beds =
      roomSceneState?.selectedRoom?.getAllItems().filter(
        (item) => item.type === ItemModelType.Bed, // && item.subType !== 'Double',
      ) || [];
    return beds;
  };

  const getBedsCount = () => {
    const beds = getBeds();
    let count = 0;
    for (let i = 0; i < beds?.length; i++) {
      count++;
      if (beds[i].subType === ItemModelSubType.TwinXLBunk) count++;
    }
    return count;
  };

  const saveAllowed = () => {
    if (!roomConfigs.bunkBed || !roomConfigs.standardBed || !roomConfigs.loftBed || !roomConfigs.bedConfig) {
      setIsError(true);
      setShowToast(true);
      setToast({
        severity: 'error',
        message: Messages.ROOM_CONFIG,
        autoHideDuration: 3000,
      });
      return false;
    }
    if (
      roomConfigs.studentCount > 10 ||
      roomConfigs.studentCount < 0 ||
      (!roomConfigs.studentCount && roomConfigs.studentCount !== 0)
    ) {
      setIsError(true);
      setShowToast(true);
      setToast({
        severity: 'error',
        messageHeader: Messages.STUDENT_COUNT_HEADER,
        message: Messages.STUDENT_COUNT,
        autoHideDuration: 3000,
      });
      return false;
    }
    const count = getBedsCount();
    if (count > roomConfigs.studentCount || count < roomConfigs.studentCount) {
      setIsError(true);
      setShowToast(true);
      setToast({
        severity: 'error',
        messageHeader: Messages.BED_COUNT_ERROR_HEADER,
        message: Messages.BED_COUNT_ERROR,
        autoHideDuration: 3000,
      });
      return false;
    }
    const beds = getBeds();
    let standardBed = false;
    let loftBed = false;
    let bunkBed = false;
    let mattressError = false;
    beds.forEach((bed) => {
      if (bed.getChildren().length < 1) mattressError = true;
      if (bed.subType === ItemModelSubType.TwinXLBunk && roomConfigs.bunkBed !== 'Yes') bunkBed = true;
      if (bed.subType === ItemModelSubType.TwinXL && roomConfigs.standardBed !== 'Yes') standardBed = true;
      if (bed.subType === ItemModelSubType.TwinXLLoft && roomConfigs.loftBed !== 'Yes') loftBed = true;
    });
    if (standardBed) {
      setShowToast(true);
      setToast({
        severity: 'error',
        messageHeader: Messages.STANDARD_NOT_ALLOWED_HEADER,
        message: Messages.REMOVE_STANDARD,
        autoHideDuration: 3000,
      });
      return false;
    }
    if (loftBed) {
      setShowToast(true);
      setToast({
        severity: 'error',
        messageHeader: Messages.LOFT_NOT_ALLOWED_HEADER,
        message: Messages.REMOVE_LOFT,
        autoHideDuration: 3000,
      });
      return false;
    }
    if (bunkBed) {
      setShowToast(true);
      setToast({
        severity: 'error',
        messageHeader: Messages.BUNK_NOT_ALLOWED_HEADER,
        message: Messages.REMOVE_BUNKS,
        autoHideDuration: 3000,
      });
      return false;
    }
    if (mattressError) {
      setShowToast(true);
      setToast({
        severity: 'error',
        message: Messages.MATTRESS_ERROR,
        autoHideDuration: 3000,
      });
      return false;
    }
    setIsError(false);
    return true;
  };

  const addStyleItem = async (selectedStyle?: any, styleItem?: any): Promise<ItemModel | undefined> => {
    console.log('Starting addStyleItem with:', { selectedStyle, styleItem });

    const beds = roomSceneState?.selectedRoom?.getAllItems().filter((item) => item.type === ItemModelType.Bed) || [];
    console.log(
      'Initial bed states:',
      beds.map((bed) => ({
        bedId: bed.itemId,
        mattress: bed.getBedFeature()?.getSlotItem(ItemBedFeatureSlot.Mattress)?.itemId || 'no mattress',
      })),
    );

    if (!styleItem) {
      console.warn('No style item provided to addStyleItem');
      return;
    }
    if (!roomSceneState?.selectedRoom) {
      console.error('No selected room available');
      return;
    }

    const tags = styleItem?.descriptorTag || styleItem?.itemDetail;
    const itemData = {
      itemId: activeMode === 'CreateStyleAssociation' ? uuidv4() : styleItem.id,
      catalogId: styleItem.catalogId || '',
      variantId: styleItem.variantId,
      assetUrl: tags?.url || '',
      type: tags?.type || '',
      subType: tags?.subType || '',
      surfaceType: tags?.surfaceType || '',
      //pass parentItemId in
      parentItemId: styleItem.parentItemId,
      //pass position/rotation (global coords) in the data
      position: [styleItem.positionX ?? 0, styleItem.positionY ?? 0, styleItem.positionZ ?? 0],
      rotation: [styleItem.rotationX ?? 0, styleItem.rotationY ?? 0, styleItem.rotationZ ?? 0],
      anchor: styleItem.anchor,
      deletable: styleItem.deletable,
      moveable: styleItem.moveable,
    };
    console.log('Created item data:', itemData);

    //add to scene
    const addedItem = roomSceneState.selectedRoom.addItem(itemData);
    if (!addedItem) {
      console.error('Failed to add item to room', itemData);
      return;
    }

    //wait until asset is loaded (important for child/anchor logic)
    await waitForItemAssetLoaded(addedItem);

    //if have parent, set it again so that child items can adopt local coords properly
    //prefer setParentAndAnchor if it exists, otherwise setParent
    if (styleItem.parentItemId) {
      const parentItem = roomSceneState.selectedRoom.getItemById(styleItem.parentItemId);
      if (parentItem) {
        if (parentItem.type === ItemModelType.Mattress) {
          console.log('Attempting to parent to mattress:', {
            mattressId: parentItem.itemId,
            bedId: parentItem.parent?.itemId || 'no bed parent',
            itemType: addedItem.type,
          });

          const mattressFeature = parentItem.getMattressFeature();
          if (mattressFeature) {
            // Let the mattress feature handle slot assignment based on item type
            switch (addedItem.type) {
              case ItemModelType.Sheets:
                mattressFeature.setSlotItem(ItemMattressFeatureSlot.Sheets, addedItem);
                break;
              case ItemModelType.Cover:
                mattressFeature.setSlotItem(ItemMattressFeatureSlot.Cover, addedItem);
                break;
              case ItemModelType.Pillow:
                mattressFeature.setSlotItem(ItemMattressFeatureSlot.Pillow, addedItem);
                break;
              case ItemModelType.ShamPillow:
                mattressFeature.setSlotItem(ItemMattressFeatureSlot.ShamPillow, addedItem);
                break;
              case ItemModelType.DecorativePillow:
                mattressFeature.setSlotItem(ItemMattressFeatureSlot.DecorativePillow, addedItem);
                break;
              case ItemModelType.AccentPillow:
                mattressFeature.setSlotItem(ItemMattressFeatureSlot.AccentPillow, addedItem);
                break;
              case ItemModelType.ThrowBlanket:
                mattressFeature.setSlotItem(ItemMattressFeatureSlot.ThrowBlanket, addedItem);
                break;
              case ItemModelType.Caddie:
                mattressFeature.setSlotItem(ItemMattressFeatureSlot.Caddie, addedItem);
                break;
              case ItemModelType.Headboard:
                mattressFeature.setSlotItem(ItemMattressFeatureSlot.Headboard, addedItem);
                break;
            }

            addedItem.props.parentItemId = parentItem.itemId;

            console.log('After slot assignment:', {
              mattressId: parentItem.itemId,
              bedId: parentItem.parent?.itemId || 'no bed parent',
              sheetsId: mattressFeature.getSlotItem(ItemMattressFeatureSlot.Sheets)?.itemId || 'no sheets',
            });

            return; // Exit early as mattress feature handles all positioning
          }
        }

        // Regular parenting for non-mattress items
        addedItem.setParentAndAnchor?.(parentItem, styleItem.anchor || '');
        addedItem.props.parentItemId = parentItem.itemId;
        console.log(
          'Final bed states:',
          beds.map((bed) => ({
            bedId: bed.itemId,
            mattress: bed.getBedFeature()?.getSlotItem(ItemBedFeatureSlot.Mattress)?.itemId || 'no mattress',
          })),
        );
      }
    }
    //set local position/rotation explicitly (for correct local child placement)
    //if there's no parent, these calls effectively set the world transform
    const localPos = new Vector3(styleItem.positionX ?? 0, styleItem.positionY ?? 0, styleItem.positionZ ?? 0);
    const localRot = new Vector3(styleItem.rotationX ?? 0, styleItem.rotationY ?? 0, styleItem.rotationZ ?? 0);
    addedItem.setPosition(localPos);
    addedItem.setRotation(localRot);

    //state update
    setStyleItemIds((prevIds: any) => [...prevIds, addedItem.itemId]);
    setStyle((prevState: any) => {
      const updatedTemplateStyleItems = [...(prevState?.configStyleItems || [])];
      const newItem = {
        id: addedItem.itemId,
        configId: rConfig?.roomieRoomConfigId,
        styleItem: {
          id: activeMode === 'CreateStyleAssociation' ? styleItem?.id : styleItem?.styleItemId,
        },
        deletable: addedItem.deletable,
        moveable: addedItem.moveable,
        parentItemId: styleItem.parentItemId,
        anchor: addedItem.anchor,
        active: true,
      };
      updatedTemplateStyleItems.push(newItem);
      return {
        ...prevState,
        configId: rConfig?.roomieRoomConfigId,
        configStyleItems: updatedTemplateStyleItems,
        styleId: selectedStyle?.id,
        configStyleId: selectedStyle?.id,
      };
    });

    //recurse for children
    if (styleItem.children?.length) {
      for (const childItem of styleItem.children) {
        //pass parent ID down
        await addStyleItem(selectedStyle, {
          ...childItem,
          parentItemId: addedItem.itemId,
        });
      }
    }

    console.log('Finished addStyleItem');

    return addedItem;
  };

  const waitForItemAssetLoaded = (item: ItemModel | null): Promise<void> => {
    if (!item || item.asset.isLoaded()) {
      return Promise.resolve();
    }

    return new Promise<void>((resolve) => {
      item.asset.getLoadedObservable().add(() => {
        resolve();
      });
    });
  };

  async function recursiveAddToRoom(layoutNode: LayoutItem, selectedRoom: RoomModel): Promise<ItemModel[]> {
    console.log('Starting recursiveAddToRoom with node:', layoutNode);
    const isRootNode = !!layoutNode.roomie_user_room_item_id;
    console.log('Is root node:', isRootNode);
    let addedItems: ItemModel[] = [];

    if (!isRootNode) {
      const addedItem = await convertAndAddToRoom(layoutNode, selectedRoom);
      if (addedItem) {
        addedItems.push(addedItem);
        console.log('Added item:', addedItem);
        await waitForItemAssetLoaded(addedItem);
      }
    }

    if (layoutNode.children && layoutNode.children.length > 0) {
      console.log('Processing children');
      const sortedChildren = sortBy(layoutNode.children, (child) => {
        if (child.itemDetail?.type === ItemModelType.Mattress) {
          return 0;
        }
        return 1;
      });

      for (const child of sortedChildren) {
        const childItems = await recursiveAddToRoom(child, selectedRoom);
        addedItems = addedItems.concat(childItems);
      }
    }

    console.log('Returning items:', addedItems);
    return addedItems;
  }

  async function convertAndAddToRoom(layoutItem: LayoutItem, selectedRoom: RoomModel): Promise<ItemModel | null> {
    console.log('Starting convertAndAddToRoom with layout item:', layoutItem);
    if (!layoutItem.itemId) {
      console.error('Layout item is missing itemId', layoutItem);
      return null;
    }

    const existingItem = selectedRoom.getItemById(layoutItem.itemId);
    if (existingItem) {
      console.log('Item already exists in room:', existingItem);
      return existingItem;
    }

    let materials: ItemMaterialProps[] | undefined;
    if (layoutItem.itemDetail?.materials) {
      try {
        materials = JSON.parse(layoutItem.itemDetail.materials);
      } catch (e) {
        console.error('Error parsing materials:', e);
        materials = undefined;
      }
    }

    const itemData = {
      itemId: layoutItem.itemId,
      catalogId: layoutItem.catalogId || '',
      variantId: layoutItem.variantId || '',
      assetUrl: layoutItem.itemDetail?.url || '',
      type: layoutItem.itemDetail?.type || '',
      subType: layoutItem.itemDetail?.subType || '',
      surfaceType: layoutItem.itemDetail?.surfaceType || '',
      materials: materials,
      parentItemId: layoutItem.parentItemId,
      anchor: layoutItem.anchor,
      position: [layoutItem.positionX || 0, layoutItem.positionY || 0, layoutItem.positionZ || 0],
      rotation: [layoutItem.rotationX || 0, layoutItem.rotationY || 0, layoutItem.rotationZ || 0],
      deletable: layoutItem.deletable,
      moveable: layoutItem.moveable,
    };

    console.log('Prepared item data:', itemData);

    const addedItem = selectedRoom.addItem(itemData);
    if (!addedItem) {
      console.error('Failed to add item to room', { itemData, layoutItem });
      return null;
    }

    console.log('Successfully added item to room:', addedItem);
    return addedItem;
  }

  const addItem = async (catalog: UniversityCatalogItemProps | any) => {
    console.log('Starting addItem with catalog:', catalog);
    const tags = catalog?.descriptorTags || catalog?.descriptorTag || catalog?.itemDetail;
    let materials: string | undefined;
    if (tags?.materials) materials = tags.materials;

    const selectedRoom = roomSceneState?.selectedRoom;
    if (!selectedRoom) {
      console.log('No selected room, exiting addItem');
      return;
    }

    const createLayoutItem = (parentId?: string): LayoutItem => {
      const item = {
        itemId: uuidv4(),
        catalogId: catalog.catalogId,
        variantId: catalog.catalogItemId || catalog?.variantId,
        itemDetail: {
          url: tags?.url || '',
          type: tags?.type || '',
          subType: tags?.subType || '',
          surfaceType: tags?.surfaceType || '',
          materials: materials,
        },
        parentItemId: parentId,
        positionX: 0,
        positionY: 0,
        positionZ: 0,
        rotationX: 0,
        rotationY: 0,
        rotationZ: 0,
        deletable: true,
        moveable: true,
        children: [],
      };
      console.log('Created layout item:', item);
      return item;
    };

    const buildLayoutTree = (): LayoutItem => {
      console.log('Building layout tree');
      const rootNode: LayoutItem = {
        roomie_user_room_item_id: 'root',
        children: [],
      };

      const mainItem = createLayoutItem();

      if (isMattressType(mainItem.itemDetail?.type || '')) {
        console.log('Item is a mattress');
        if (!isBedItem) {
          console.log('No bed item selected for mattress');
          setShowToast(true);
          setToast({
            severity: 'error',
            message: Messages.SELECT_BED,
            autoHideDuration: 3000,
          });
          return rootNode;
        }

        const selectedItem = selectedRoom.selectedItem;
        if (!selectedItem) {
          console.log('No selected item for mattress parent');
          return rootNode;
        }

        mainItem.parentItemId = selectedItem.itemId;
        mainItem.anchor = MATTRESS_ANCHOR;
        console.log('Set mattress parent:', selectedItem.itemId);

        if (isBunk(selectedItem?.subType || '')) {
          console.log('Creating bunk mattress');
          const bunkMattress = createLayoutItem(selectedItem.itemId);
          bunkMattress.anchor = BUNK_ANCHOR;
          mainItem.children.push(bunkMattress);
        }
      }

      rootNode.children.push(mainItem);
      console.log('Final layout tree:', rootNode);
      return rootNode;
    };

    const layoutTree = buildLayoutTree();

    console.log('Calling recursiveAddToRoom with layout tree');
    await recursiveAddToRoom(layoutTree, selectedRoom);

    refresh();
    console.log('Finished addItem');
  };

  const removeElement = async () => {
    const selectedItem = roomSceneState?.selectedRoom?.selectedItem;
    if (selectedItem) {
      if (selectedItem.type === ItemModelType.Bed) {
        const children = selectedItem?.getChildren() || [];
        for (let i = 0; i < children?.length; i++) roomSceneState?.selectedRoom?.removeItem(children[i], false);
      }
      roomSceneState?.selectedRoom?.removeItem(selectedItem, false);
      setIsError(false);
    }

    refresh();
  };

  const saveRoom = async () => {
    let isSaveAllowed = true;
    if (SHOW_BED_TOGGLE_CONFIGURATIONS) isSaveAllowed = saveAllowed();
    if (isSaveAllowed) {
      const layout = roomSceneToLayoutItems(roomSceneState);
      await saveLayout(
        {
          roomie_room_config_id: loadLayoutRes?.data?.roomieRoomConfigId,
          templateItems: layout,
        },
        async (saveRoomResp) => {
          if (saveRoomResp.data.code == 0) {
            if (activeMode === 'CreateStyleAssociation') {
              setActiveMode('TemplateView');
              getAssociatedStyles();
              resetRoom();
            }
            await loadLayout({ id: loadLayoutRes?.data?.roomieRoomConfigId, token: '' });
            setToast({
              severity: 'success',
              message: ROOM_SAVED_SUCCESS,
              autoHideDuration: 3000,
            });
            setShowToast(true);
          }
        },
      );
    }
  };

  const createAssociateStyle = async () => {
    console.log('Creating association style');
    let isSaveAllowed = true;
    if (SHOW_BED_TOGGLE_CONFIGURATIONS) isSaveAllowed = saveAllowed();
    if (isSaveAllowed) {
      const layout = roomSceneToLayoutItems(roomSceneState);
      const filteredLayout = layout.filter((item) => styleItemIds.includes(item?.itemId));

      const updatedStyleItems = style.configStyleItems.map((styleItem: any) => {
        const matchingLayoutItem = filteredLayout.find((item: any) => item.itemId === styleItem.id);
        if (matchingLayoutItem) {
          const parentItem = filteredLayout.find((item) => item.itemId === matchingLayoutItem.parentItemId);
          console.log('Original scene positions:', {
            child: matchingLayoutItem,
            parent: parentItem,
          });
          return {
            ...styleItem,
            positionX: matchingLayoutItem.positionX,
            positionY: matchingLayoutItem.positionY,
            positionZ: matchingLayoutItem.positionZ,
            rotationX: matchingLayoutItem.rotationX,
            rotationY: matchingLayoutItem.rotationY,
            rotationZ: matchingLayoutItem.rotationZ,
            parentItemId: matchingLayoutItem.parentItemId,
            anchor: matchingLayoutItem.anchor,
            deletable: true,
          };
        }
        return styleItem;
      });
      const appliedStylePayload = {
        styleId: style?.styleId,
        configId: style?.configId,
        ...(activeMode === 'UpdateStyleAssociation' && { configStyleId: style?.configStyleId }),
        configStyleItems: updatedStyleItems,
      };

      console.log('Payload being sent:', appliedStylePayload);

      await createAssociationApi(appliedStylePayload, async (saveRoomResp) => {
        if (saveRoomResp.data.code == 0) {
          if (activeMode === 'CreateStyleAssociation' || activeMode === 'UpdateStyleAssociation') {
            setActiveMode('TemplateView');
            getAssociatedStyles();
            resetRoom(true);
            setToast({
              severity: 'success',
              message: ROOM_SAVED_SUCCESS,
              autoHideDuration: 3000,
            });
            setShowToast(true);
          }

          //log after style load
          const loadedStyle = await loadLayout({ id: loadLayoutRes?.data?.roomieRoomConfigId, token: '' });
          console.log('Loaded style data:', loadedStyle);

          setStyle({ ...STYLE_INITIAL });
        }
      });
    }
  };

  const resetRoom = (disableMessage?: boolean) => {
    if (loadLayoutRes && roomSceneState) {
      setIsError(false);
      setIsLoaded(false);
      const room = roomSceneState.addRoom({
        title: loadLayoutRes?.data?.building_name || '',
        assetUrl: loadLayoutRes?.data?.roomie_model_url,
        isLocked: isLocked === 'true' && !roomInfo.admin,
        setIsLoaded: setIsLoaded,
      });

      const convertedItems = layoutConfigToRoomItems(loadLayoutRes?.data?.templateItems);
      room.addItems(convertedItems);

      // Re-establish parent relationships after all items are added
      convertedItems.forEach((itemData) => {
        if (itemData.parentItemId && typeof itemData.itemId === 'string') {
          const child = room.getItemById(itemData.itemId);
          const parent = room.getItemById(itemData.parentItemId);
          if (child && parent) {
            child.setParentAndAnchor?.(parent, itemData.anchor || '');
          }
        }
      });

      roomSceneState.setSelectedRoom(room);
      if (disableMessage) {
        return;
      }
      setToast({
        severity: 'success',
        message: ROOM_RESET_SUCCESS,
        autoHideDuration: 3000,
      });
      setShowToast(true);
    }

    refresh();
  };
  // const removeRoomItems = () => {
  //   const layout = roomSceneToLayoutItems(roomSceneState);
  //   layout?.forEach((item: any) => {
  //     const itemModel = roomSceneState?.selectedRoom?.getItemById(item?.itemId);
  //     if (itemModel) {
  //       roomSceneState?.selectedRoom?.removeItem(itemModel, false);
  //     }
  //   });
  // };

  const closeDialog = () => {
    const data = loadLayoutRes?.data;
    const config = {
      modelUrl: data.roomie_model_url || '',
      panoUrl: data?.panoUrl || '',
      roomDescription: data?.roomDescription || '',
      configName: data?.roomieConfigName || '',
      friendlyName: data?.friendlyName || '',
      typeName: data?.type || '',
    };
    setConfigInfo(config);
    setShowEditConfigModal(false);
  };

  const saveConfig = async () => {
    const config = {
      configId: loadLayoutRes?.data?.roomieRoomConfigId,
      modelUrl: configInfo.modelUrl,
      type: configInfo.typeName,
      panoUrl: configInfo.panoUrl,
      roomConfigName: configInfo.configName,
      friendlyName: configInfo.friendlyName,
      roomDescriptionBlurb: configInfo.roomDescription,
    };
    await editConfig(config);
    await loadLayout({ id: loadLayoutRes?.data?.roomieRoomConfigId, token: '' });
  };

  const updateStatus = async (status: string) => {
    setStatus(status);
    await updateRoomStatus({ status, configId: loadLayoutRes?.data?.roomieRoomConfigId });
  };

  const renderTemplate = async (config: GuideView) => {
    setIsLoaded(false);
    setToken(config.token);
    await loadAdminLayout(config, true);
  };

  const loadLayoutOnDelete = async () => {
    await loadAdminLayout(rConfig);
  };

  useEffect(() => {
    if (activeMode !== 'TemplateView') {
      setIsEnableCatalogList(true);
      return;
    }
    setIsEnableCatalogList(false);
  }, [activeMode]);

  const CustomTooltip = () => (
    <div className={classes.tooltip}>
      <img src={UpDownKeys} className={classes.arrowKeys} />
      Select bed then tap the up/down keys to customize bed height
    </div>
  );

  const Button = ({
    background = 'none',
    border = 'none',
    solid = false,
    title,
    borderRadius = '34px',
    onClick,
    width = '230px',
    enable = true,
  }: ButtonProps) => (
    <CustomButton
      title={title}
      width={width}
      height="48px"
      borderRadius={borderRadius}
      mTop="0px"
      mBottom="21px"
      buttonClicked={onClick}
      textColor={Colors.cyprus}
      border={border}
      solid={solid}
      background={background}
      enable={enable}
    />
  );

  const roomItems = roomSceneState?.selectedRoom?.getAllItems() ?? [];
  const layoutItems = (loadLayoutRes?.data.templateItems ?? []) as LayoutItem[];

  return (
    <>
      <CircularProgress
        isLoading={
          loadLayoutLoading ||
          saveLayoutLoading ||
          catalogsLoading ||
          roomsLoading ||
          editConfigLoading ||
          updateStatusLoading
        }
      />
      <Snackbar
        isOpen={showToast}
        setIsOpen={setShowToast}
        messageHeader={toast?.messageHeader}
        severity={toast?.severity || 'success'}
        message={toast?.message || ''}
        autoHideDuration={toast?.autoHideDuration}
      />
      {roomInfo.admin && (
        <Drawer
          variant="persistent"
          anchor="left"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.list}>
            <div className={classes.topContainer}>
              {activeMode === 'TemplateView' && (
                <div className={classes.flex} onClick={() => history.push(Routes.DEFAULT)}>
                  <ArrowBackIos className={classes.backIcon} />
                  <div className={classes.backText}>Back to Admin</div>
                </div>
              )}
              {(activeMode === 'CreateStyleAssociation' || activeMode === 'UpdateStyleAssociation') && (
                <div
                  className={classes.flex}
                  onClick={() => {
                    resetRoom(true);
                    setActiveMode('TemplateView');
                  }}
                >
                  <ArrowBackIos className={classes.backIcon} />
                  <div className={classes.backText}>Back to Template</div>
                </div>
              )}
            </div>
            {/* <div className={classes.stylesNavButton}>
              <ButtonMUI
                variant="contained"
                onClick={() =>
                  history.push({
                    pathname: Routes.ROOM_STYLES,
                    search: `?room_config_id=${loadLayoutRes?.data?.roomieRoomConfigId}`,
                  })
                }
                style={{
                  width: '245px',
                  borderRadius: '10px',
                  height: '55px',
                  background: Colors.paleTurquoise,
                  color: Colors.cyprus,
                }}
                startIcon={<StyleIcon fill={Colors.paleTurquoise} />}
              >
                Style This Template
              </ButtonMUI>
            </div> */}
            <div>
              {activeMode === 'CreateStyleAssociation' && (
                <AsyncAutocomplete
                  resetRoom={resetRoom}
                  addStyleItem={addStyleItem}
                  setIsEnableCatalogList={setIsEnableCatalogList}
                  setStyle={setStyle}
                />
              )}
              {/* {style && appliedStyleId && (
                <div className={classes.appliedStyleWrapper}>
                  <div>
                    <img height="60" width="90" src={style?.styleThumbnail} />
                  </div>
                  <div>
                    <h3>{style?.styleName}</h3>
                  </div>
                </div>
              )} */}
              {status && (
                <FormControl className={classes.formControl}>
                  <InputLabel id="select-status">Status</InputLabel>
                  <Select
                    value={status}
                    disabled={!authContext?.roomInfo?.isRoomieEmployee}
                    onChange={(e) => updateStatus(e.target.value as string)}
                    displayEmpty
                    labelId="select-status"
                    className={classes.select}
                  >
                    {STATUS_OPTIONS.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
            <div className={classes.header}>{loadLayoutRes?.data?.roomieConfigName || ''}</div>
            <div className={classes.buttonsWrapper}>
              <CopyButton isCopied={isCopied} onClick={copy} />
              {authContext?.roomInfo?.isRoomieEmployee && (
                <CustomButton
                  title="Edit Data"
                  padding="0px"
                  mTop="0px"
                  background={Colors.paleTurquoise}
                  fontSize="14px"
                  borderRadius="28px"
                  height="34px"
                  textColor={Colors.cyprus}
                  width="118px"
                  buttonClicked={() => setShowEditConfigModal(true)}
                />
              )}
            </div>
            {SHOW_BED_TOGGLE_CONFIGURATIONS && (
              <div className={classes.roomConfiguration}>
                <div className={classes.configurationText}>Room Configuration</div>
                {ROOM_CONFIGURATIONS.map((item) => (
                  <RadioField
                    key={item.key}
                    title={item.title}
                    value={roomConfigs[item.key] as string}
                    setValue={(value) => setValueInRoomConfigs(item.key, value)}
                    options={item.options}
                  />
                ))}
                <div className={classes.label}>Student count:</div>
                <InputFieldWithLabel
                  value={roomConfigs['studentCount']}
                  onChange={(e) => {
                    setIsError(false);
                    setValueInRoomConfigs('studentCount', Number(e.target.value));
                  }}
                  placeholder="Enter number"
                  italicPlaceholder
                  isError={isError}
                  padding="10px 11px"
                  width="140px"
                  mTop="8px"
                  type="number"
                  background={Colors.plain_white}
                  borderRadius="2px"
                />
              </div>
            )}

            <Tabs
              scrollButtons="on"
              variant="scrollable"
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
            >
              <Tab label="Catalog" style={{ minWidth: 0 }} className={classes.tab} />
              <Tab label="Items" style={{ minWidth: 0 }} className={classes.tab} />
              <Tab label="Layout" style={{ minWidth: 0 }} className={classes.tab} />
              <Tab label="Associated Styles" style={{ minWidth: 0 }} className={classes.tab} />
            </Tabs>

            <TabDisplay value={tabValue} index={0}>
              <>
                {catalogs.map(
                  (catalog) =>
                    catalog.active &&
                    !isPillowType(catalog.itemName) && (
                      <UniversityCatalog
                        key={catalog.catalogId}
                        catalog={catalog || []}
                        addItem={addItem}
                        listRef={listRef}
                        isEnableCatalogList={isEnableCatalogList}
                      />
                    ),
                )}
              </>
            </TabDisplay>

            <TabDisplay value={tabValue} index={1}>
              <>
                {sortBy(roomItems, 'itemId')
                  .filter((item) => !item.parent)
                  .map((item) => (
                    <RoomItemTree
                      key={item.itemId}
                      catalogs={catalogs}
                      item={item}
                      items={roomItems}
                      onChange={refresh}
                    />
                  ))}
              </>
            </TabDisplay>

            <TabDisplay value={tabValue} index={2}>
              <>
                {sortBy(layoutItems, 'itemId')
                  .filter(
                    (layoutItem: LayoutItem) =>
                      !layoutItem.parentItemId || !layoutItems.find((item) => item.itemId === layoutItem.parentItemId),
                  )
                  .map((layoutItem: LayoutItem) => (
                    <LayoutItemTree
                      key={layoutItem.itemId}
                      catalogs={catalogs}
                      layoutItem={layoutItem}
                      layoutItems={layoutItems}
                    />
                  ))}
              </>
            </TabDisplay>

            <TabDisplay value={tabValue} index={3}>
              {appliedStyles &&
                Array.isArray(appliedStyles) &&
                appliedStyles.map((style: ConfigStyle, index: number) => (
                  <AppliedStyles
                    style={style}
                    key={index}
                    addStyleItem={addStyleItem}
                    getAssociatedStyles={getAssociatedStyles}
                    setActiveMode={setActiveMode}
                    resetRoom={resetRoom}
                    loadLayout={loadLayoutOnDelete}
                  />
                ))}
            </TabDisplay>
          </div>

          <div className={classes.buttons}>
            {showButton && (
              <Button
                title={'Remove Item'}
                enable={!isEnableCatalogList}
                border={`1px solid ${Colors.frenchPass}`}
                onClick={removeElement}
              />
            )}
            {activeMode === 'TemplateView' && (
              <Button title="Save Room" solid onClick={saveRoom} background={Colors.paleTurquoise} />
            )}
            {activeMode === 'CreateStyleAssociation' && (
              <Button
                enable={style && style?.styleId}
                title="Associate Style"
                solid
                onClick={createAssociateStyle}
                background={Colors.paleTurquoise}
              />
            )}
            {activeMode === 'UpdateStyleAssociation' && (
              <Button
                title="Update Associated Style"
                solid
                onClick={createAssociateStyle}
                background={Colors.paleTurquoise}
              />
            )}
            <Button title="Reset Room" onClick={resetRoom} border={`1px solid ${Colors.frenchPass}`} />
          </div>

          {isBedItem && (activeMode === 'UpdateStyleAssociation' || activeMode === 'CreateStyleAssociation') && (
            <div>
              {/* Add Bed Item Button */}
              <CustomButton
                title={'Add Bed Item'}
                width="125px"
                mTop="0"
                mBottom="10px"
                mLeft="20px"
                mRight="10px"
                buttonClicked={handleAddBedItemClick}
              />

              {/* Remove Bed Item Button */}
              <CustomButton
                title={'Remove Bed Item'}
                width="105px"
                mTop="0"
                mBottom="10px"
                mLeft="10px"
                mRight="10px"
                buttonClicked={handleRemoveBedItemClick}
              />

              {/* Clear Bed Items Button */}
              <CustomButton
                title={'Clear Bed Items'}
                width="110px"
                mTop="0"
                mBottom="10px"
                mLeft="20px"
                mRight="10px"
                buttonClicked={() => {
                  clearBedItems();
                }}
              />

              {/* Add Bed Item Dropdown */}
              {isAddBedItemOpen && (
                <div>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="select-style-item-label">Select Style Item</InputLabel>
                    <Select
                      labelId="select-style-item-label"
                      value={selectedStyleItem?.itemId || ''}
                      onChange={(event) => {
                        const itemId = event.target.value;
                        const item = fixedStyleItems.find((item) => item.itemId === itemId);
                        setSelectedStyleItem(item || null);
                      }}
                    >
                      {fixedStyleItems.map((item) => (
                        <MenuItem key={item.itemId} value={item.itemId}>
                          {item.title || item.type}
                        </MenuItem>
                      ))}
                    </Select>
                    <CustomButton
                      title="Add Item to Bed"
                      buttonClicked={() => {
                        if (selectedStyleItem) {
                          addBedItem(selectedStyleItem);
                          setIsAddBedItemOpen(false);
                        }
                      }}
                    />
                  </FormControl>
                </div>
              )}

              {/* Remove Bed Item Dropdown */}
              {isRemoveBedItemOpen && (
                <div>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="select-bed-item-label">Select Bed Item to Remove</InputLabel>
                    <Select
                      labelId="select-bed-item-label"
                      value={selectedBedItemToRemove?.itemId || ''}
                      onChange={(event) => {
                        const itemId = event.target.value;
                        const item = itemsOnBed.find((item) => item.itemId === itemId);
                        setSelectedBedItemToRemove(item || null);
                      }}
                    >
                      {itemsOnBed.map((item) => (
                        <MenuItem key={item.itemId} value={item.itemId}>
                          {item.title || item.type}
                        </MenuItem>
                      ))}
                    </Select>
                    <CustomButton
                      title="Remove Item from Bed"
                      buttonClicked={() => {
                        if (selectedBedItemToRemove) {
                          removeBedItem(selectedBedItemToRemove);
                          setIsRemoveBedItemOpen(false);
                        }
                      }}
                    />
                  </FormControl>
                </div>
              )}
            </div>
          )}
        </Drawer>
      )}

      {!!loadLayoutRes?.data?.templateItems && (
        <RoomViewer
          isSharedUser={false}
          drawerOpen={true}
          onItemSelected={(ItemModel: any) => {
            onItemSelected(ItemModel);
          }}
          onMeasureTool={onMeasureTool}
          setIsLoaded={setIsLoaded}
          roomLoaded={true}
          setRoomSceneState={setRoomSceneStateRef}
          buildingName={loadLayoutRes?.data?.roomieConfigName}
          roomModelUrl={loadLayoutRes?.data?.roomie_model_url}
          items={layoutConfigToRoomItems(loadLayoutRes?.data?.templateItems)}
        />
      )}
      {showTooltip && roomInfo.admin && <CustomTooltip />}
      {isMeasuring && (
        <div className={classes.ButtonBarBottom}>
          <CustomIconButtonSecondary
            title="Clear measurement"
            buttonPressed={() => clearMeasurementPressed()}
            setActiveValue={(item) => setClearMeasurementHovered(item)}
            active={isMeasuring}
            icon={<CancelIcon fill={clearMeasurementHovered ? Colors.plain_white : Colors.hilighted_text} />}
          />
        </div>
      )}

      {activeMode === 'TemplateView' && (
        <div className={classes.createStyleButton}>
          <Button
            title="Create Style Association"
            borderRadius="6px"
            solid
            onClick={() => {
              setActiveMode('CreateStyleAssociation');
            }}
            background={Colors.paleTurquoise}
            width="250px"
          />
        </div>
      )}
      <RightMeasurementButton roomScene={roomSceneState} activateMeasurement={showMeasureButton} />
      <RoomViewerOnScreenText textToDisplay={onScreenTextvalue} color={Colors.plain_black} />
      <EditConfigDialog
        isOpen={showEditConfigModal}
        setIsOpen={setShowEditConfigModal}
        configInfo={configInfo}
        setConfigInfo={setConfigInfo}
        saveConfig={saveConfig}
        closeDialog={closeDialog}
      />
      <PhotoViewerDialog
        isOpen={showModal}
        setIsOpen={setShowModal}
        url={loadLayoutRes?.data?.panoUrl}
        buildingName={loadLayoutRes?.data?.roomieConfigName || ''}
      />
      <div className={classes.photoButton}>
        <Button
          title="360 Photo"
          borderRadius="6px"
          solid
          onClick={() => setShowModal(true)}
          background={Colors.paleTurquoise}
          width="150px"
        />
      </div>
      {authContext?.roomInfo?.isRoomieEmployee && <div className={classes.typeText}>{loadLayoutRes?.data?.type}</div>}
      <div className={classes.bottomButtons}>
        {previousTemplate && (
          <Button
            title="Previous"
            solid
            onClick={async () => await renderTemplate(previousTemplate)}
            background={Colors.paleTurquoise}
            width="150px"
          />
        )}
        {nextTemplate && (
          <Button
            title="Next"
            solid
            onClick={async () => await renderTemplate(nextTemplate)}
            background={Colors.paleTurquoise}
            width="150px"
          />
        )}
      </div>
    </>
  );
};

export default AdminPortal;

function TabDisplay({ children, value, index }: PropsWithChildren<{ value: number; index: number }>) {
  if (value !== index) {
    return null;
  }

  return <div style={{ paddingTop: 16 }}>{children}</div>;
}

const useModalStyles = makeStyles((theme) => ({
  paper: {
    position: 'fixed',
    minWidth: 500,
    maxWidth: '100vw',
    overflowX: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function RoomItemTree({
  item,
  items,
  catalogs,
  onChange,
}: {
  item: ItemModel;
  items: ItemModel[];
  catalogs: UniversityCatalogProps[];
  onChange: () => void;
}) {
  const classes = useModalStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const children = item.getChildren();
  const hasChildren = children.length > 0;
  const itemName = catalogs
    .flatMap((catalog) => catalog.catalogItems)
    .find((catalogItem) => catalogItem.catalogItemId === item.variantId)?.itemVariantName;

  return (
    <Accordion expanded={hasChildren ? undefined : false} style={{ flexGrow: 1 }}>
      <AccordionSummary
        expandIcon={hasChildren ? <ExpandMoreIcon /> : null}
        style={{ paddingInline: 6, fontSize: 'small' }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              item.remove();
              onChange();
            }}
          >
            <Delete fontSize="small" />
          </IconButton>

          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            <Info fontSize="small" />
          </IconButton>

          <span>
            {itemName}{' '}
            <i title={item.itemId} style={{ color: 'grey' }}>
              ({item.itemId.slice(0, 5)}...)
            </i>
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        {sortBy(children, 'itemId').map((child) => (
          <RoomItemTree key={child.itemId} item={child} items={items} catalogs={catalogs} onChange={onChange} />
        ))}
      </AccordionDetails>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={classes.paper}>
          <pre style={{ maxWidth: '100%' }}>{JSON.stringify(item.props, null, 2)}</pre>
        </div>
      </Modal>
    </Accordion>
  );
}

function LayoutItemTree({
  layoutItem,
  layoutItems,
  catalogs,
}: {
  layoutItem: LayoutItem;
  layoutItems: LayoutItem[];
  catalogs: UniversityCatalogProps[];
}) {
  const classes = useModalStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const children = layoutItems.filter((item) => item.parentItemId === layoutItem.itemId) ?? [];
  const hasChildren = children.length > 0;
  const itemName = catalogs
    .flatMap((catalog) => catalog.catalogItems)
    .find((catalogItem) => catalogItem.catalogItemId === layoutItem.variantId)?.itemVariantName;

  return (
    <Accordion expanded={hasChildren ? undefined : false} style={{ flexGrow: 1 }}>
      <AccordionSummary
        expandIcon={hasChildren ? <ExpandMoreIcon /> : null}
        style={{ paddingInline: 6, fontSize: 'small' }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            <Info fontSize="small" />
          </IconButton>

          <span>
            {itemName}{' '}
            <i title={layoutItem.itemId} style={{ color: 'grey' }}>
              ({layoutItem.itemId?.slice(0, 5)}...)
            </i>
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        {sortBy(children, 'itemId').map((child) => (
          <LayoutItemTree key={child.itemId} layoutItem={child} layoutItems={layoutItems} catalogs={catalogs} />
        ))}
      </AccordionDetails>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={classes.paper}>
          <pre style={{ maxWidth: '100%' }}>{JSON.stringify(layoutItem, null, 2)}</pre>
        </div>
      </Modal>
    </Accordion>
  );
}
